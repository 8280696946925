@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

$color-primary: hsl(124, 28%, 60%);
$color-primary-light: hsl(124, 50%, 90%);
$color-primary-dark: hsl(124, 100%, 28%);

$color-secondary-1: hsl(42, 74%, 45%);
$color-secondary-2: hsl(42, 74%, 55%);
$color-secondary-3: hsl(42, 74%, 65%);
$color-secondary-4: hsl(42, 74%, 75%);
$color-secondary-5: hsl(42, 74%, 85%);

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    font-family: 'Inter';
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

header {
    text-align: center;

    span {
        display: inline-block;
        position: relative;
    }

    h1 {
        color: $color-primary;
        font-size: 3rem;
        font-family: 'Roboto Slab';
        font-weight: 700;
        padding-block: 4rem;

        span {
            animation: slide 1s forwards;
            transform: translateX(-2ch);
        }
    }

    h2 {
        font-weight: 500;
        font-family: 'Roboto Slab';
        font-size: 1.3rem;
        padding: 2rem;
        background-color: $color-secondary-2;

        span {
            animation: slide 1s forwards;
            transform: translateX(2ch);
        }
    }

}

.home__cards {
    padding: 3rem;
    margin: 3rem;
    background-color: $color-secondary-5;
    text-align: center;
    display: flex;
    gap: 2rem;
    flex-direction: row;

    h2 {
        font-family: 'Roboto Slab';

    }
}

.home__cards>* {
    flex-basis: 0;
    flex-grow: 1;
}

@keyframes slide {
    100% {
        transform: translateX(0);
    }
}

.navigation {
    display: block;
    margin: 4rem auto;
    padding: 1rem 4rem;
    border-radius: 0.5rem;
    font-size: 1.5rem;
    font-family: 'Roboto Slab';
    background-color: $color-primary-light;
    color: $color-primary-dark;
    border: 0;
    animation: jump 1s;
}

.navigation:hover {
    color: $color-primary-light;
    background-color: $color-primary-dark;
    cursor: pointer;
}

.navigation:active {
    transform: scale(0.95);
}

@keyframes jump {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}


#survey {
    background-color: $color-secondary-5;
    min-height: 100vh;
    padding: 4rem;
    display: grid;
    align-items: center;

    h1 {
        text-align: center;
        margin: 3rem;
        color: $color-primary-dark;
        font-family: 'Roboto Slab';
    }

    .form {
        display: grid;
        place-items: center;

        .Mui-checked,
        .Mui-focused {
            color: $color-primary-dark;
        }

        .MuiDivider-wrapper {
            margin: 2rem 0;
        }

        p {
            margin: 1rem auto;
        }
    }

    .form-wrapper {
        display: flex;
        flex-direction: row;
        padding-inline: 5rem;

        >*:first-child {
            flex: 2 2 0;
        }

        >*:nth-child(2) {
            flex: 1 1 0;
        }

        aside {
            background-color: $color-primary-light;
            color: #555;
            padding: 3ch;
            min-width: 20ch;
            max-height: 30ch;
            overflow-y: scroll;
        }
    }

    .pagination {
        margin: 2rem auto;

        .Mui-selected {

            color: $color-primary-dark;
            background-color: $color-primary-light;
        }
    }
}


#summary {
    background-color: $color-secondary-5;
    min-height: 100vh;
    padding: 4rem;

    .stats {
        display: flex;
        flex-direction: row;
        gap: 3rem;
        margin: 3rem auto;
        text-align: center;

        >* {
            flex: 1 1;
            padding: 3rem;
            aspect-ratio: 1;
            place-items: center;
            display: grid;
            border-radius: 50%;

            p:nth-child(2) {
                color: $color-secondary-1;
                font-size: 1.25rem;
            }
        }


        .stat {
            color: $color-primary-dark;
            font-family: 'Roboto Slab';
            font-size: 2rem;
            font-weight: bold;
        }

    }
}

.fluid {
    font-size: 4rem;
    text-align: center;
    margin-bottom: 5rem;
    font-family: 'Roboto Slab';
    color: $color-secondary-1;
}

.plots {
    display: grid;
    place-items: center;
    overflow-x: auto;
}

.testimonials {
    display: flex;
    flex-direction: row;
    justify-items: center;
    width: 80%;
    margin: 0 auto;

    >* {
        flex: 1 1 0;
        padding: 3rem;
        text-align: center;

        .avatar {
            border-radius: 50%;
            overflow: hidden;
            width: 300px;
            height: 300px;
            margin: 3rem auto;

            img {
                width: 100%
            }
        }
    }
}

.gift {
    margin: 3rem;
    padding: 3rem;
}

#congratulations {
    text-align: center;
    padding: 2rem;
    position: sticky;
    top: 0;
    background-color: #fff;
    box-shadow: #aaa 0 0 5px;


    h2 {
        color: $color-secondary-2;
        font-size: 2rem;
    }
}

a,
a:visited {
    color: $color-primary-dark;
    text-decoration: none;
}

a:hover {
    color: $color-primary;
}


@media screen and (max-width: 600px) {
    .home__cards {
        margin: -1rem;
    }


}

@media screen and (max-width: 900px) {
    .home__cards {
        flex-direction: column;
    }

    .home__cards>* {
        flex-basis: initial;
        flex-direction: column;
    }

    #survey {
        padding: 0;

        .form-wrapper {
            display: flex;
            flex-direction: column;
            padding-inline: 3rem;
            gap: 2rem;
        }
    }

    #summary .stats {
        flex-direction: column;
        gap: 4rem;
    }

    .testimonials {
        flex-direction: column;

        .avatar {
            width: 100%;
        }
    }


}